import React, { Suspense } from 'react'

const Lazy =
  // eslint-disable-next-line react/display-name
  (Component: React.LazyExoticComponent<() => JSX.Element>) => (props: Record<string, unknown>) =>
    (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    )

export default Lazy
