import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { PropsWithChildren, useMemo } from 'react'
import createTheme from '../../themes'

const ThemeProvider = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useMemo(() => createTheme(), [])
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}

export default ThemeProvider
