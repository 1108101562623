import { createTheme } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'

const theme = (mode?: PaletteMode) => {
  return createTheme({
    palette: {
      text: {
        primary: '#ffffff',
      },
      background: {
        default: '#122d3e',
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            width: '100%',
            height: '100%',
          },
          body: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          },
        },
      },
    },
  })
}

export default theme
