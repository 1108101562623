import { lazy } from 'react'
import Loadable from './Loadable'
import MainLayout from 'layouts/main/MainLayout'

const ARLanding = Loadable(lazy(() => import('features/ar/ARLanding')))
const ARDashboard = Loadable(lazy(() => import('features/ar/ARDashboard')))

/**
 * Routes.
 */
export const routes = {
  path: '/',
  element: <MainLayout />,
  children: [
    /**
     * Landing
     */
    {
      path: '/',
      element: <ARLanding />,
    },
    /**
     * Dashboard
     */
    {
      path: '/:slug',
      element: <ARDashboard />,
    },
  ],
}
