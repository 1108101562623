import { createSlice } from '@reduxjs/toolkit'
import { api as arApi, ARSetup, ARModel } from 'app/services/ar'
import { RootState } from 'app/store'

export type ARState = {
  setup?: ARSetup
  model?: ARModel
}

const initialState: ARState = {
  setup: undefined,
  model: undefined,
}

const slice = createSlice({
  name: 'ar',
  initialState,
  reducers: {
    setCurrentModel(state, { payload }) {
      state.model = payload
    },
  },
  extraReducers: (build) => {
    build.addMatcher(arApi.endpoints.getCollection.matchFulfilled, (state, { payload }) => {
      if (!payload.slug) return
      state.setup = payload
      state.model = payload.collection[0]
    })
  },
})

export const selectCurrentModel = (state: RootState) => state.ar.model
export const selectCurrentSetup = (state: RootState) => state.ar.setup

export const { setCurrentModel } = slice.actions

export default slice.reducer
