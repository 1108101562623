import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'app/constants'

export type ARTheme = {
  color: string
  background: string
  borderColor: string
}

export type ARModel = {
  uid: number
  thumb: string
  source: string
  label?: string
  radius: number
  rotation: number
}

export type ARSetup = {
  slug: string
  brand: {
    name: string
    logo: string
    theme: {
      background: string
      progress: string
    }
  }
  arbutton: {
    label: string
    theme: ARTheme
  }
  thumbnails: {
    theme: ARTheme
  }
  collection: ARModel[]
}

export const api = createApi({
  reducerPath: 'arApi',
  tagTypes: ['AR'],
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (build) => ({
    getCollection: build.query<ARSetup, string>({
      query: (slug) => `collection.php?slug=${slug}`,
    }),
  }),
})

export const { useGetCollectionQuery } = api
