import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { Suspense } from 'react'
import ThemeProvider from './providers/theme'
import Routes from './router'

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline />
        <Suspense fallback={null}>
          <Routes />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
